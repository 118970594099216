const config = require('./config');
const _merge = require('lodash/merge');

let env = process.env.NODE_ENV || 'test';

const { defaults } = config;

const current = config[env];

if (!current) {
	throw new Error(`Can't find config for NODE_ENV=${env}`);
}

const result = _merge({ env }, defaults, current);

module.exports = result;
