module.exports = {
	defaults: {
		port: process.env.PORT || process.env.vera_mv_port || 8080,
		session: {
			secret: 'shouldbeprettysecure',
			maxAge: 2 * 60 * 60 * 1000,
		},
		backEndUrl: `http://${process.env.vera_mv_backend_url ? process.env.vera_mv_backend_url : 'localhost'}:8001`,
		//172.31.156.3 - TEST
		//172.31.155.66 - QA
		//172.31.155.194 - PROD
		bypassLogin: true,
		secure: false,
		DIFI: {
			url: process.env.vera_mv_difi_url,
			id: process.env.vera_mv_difi_id,
			secret: process.env.vera_mv_difi_secret,
		},
	},

	test: {},

	production: {
		bypassLogin: (process.env.BYPASS_LOGIN || process.env.vera_mv_bypass_login) === 'true',
		secure: process.env.vera_mv_use_https === 'true',
	},
};
