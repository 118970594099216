import { combineReducers } from 'redux';

import { clear, createFetchReducer, success } from '../utils/action-helpers';

const GET_LEARNING_PORTAL_SSO_LINK = 'GET_LEARNING_PORTAL_SSO_LINK';
const GET_LEARNING_PORTAL_COURSES_FOR_VERGE = 'GET_LEARNING_PORTAL_COURSES_FOR_VERGE';

const initialState = {
	ssoUrl: {
		val: '',
	},
	vergeCourses: [],
};

export function getSsoUrl() {
	return {
		type: GET_LEARNING_PORTAL_SSO_LINK,
		url: `/api/verge/learning-portal-sso`,
		fetchOptions: {},
		json: true,
	};
}

export function clearSsoUrl() {
	return {
		type: clear(GET_LEARNING_PORTAL_SSO_LINK),
	};
}

function ssoUrl(state = initialState.ssoUrl, action) {
	switch (action.type) {
		case success(GET_LEARNING_PORTAL_SSO_LINK):
			return action.response;
		case clear(GET_LEARNING_PORTAL_SSO_LINK):
			return initialState.ssoUrl;
		default:
			return state;
	}
}

export function getVergeCourses() {
	return {
		type: GET_LEARNING_PORTAL_COURSES_FOR_VERGE,
		url: `/api/verge/learning-portal-completed-courses`,
		fetchOptions: {},
		json: true,
	};
}

export function clearVergeCourses() {
	return {
		type: clear(GET_LEARNING_PORTAL_COURSES_FOR_VERGE),
	};
}

function vergeCourses(state = initialState.vergeCourses, action) {
	switch (action.type) {
		case success(GET_LEARNING_PORTAL_COURSES_FOR_VERGE):
			return action.response;
		case clear(GET_LEARNING_PORTAL_COURSES_FOR_VERGE):
			return initialState.vergeCourses;
		default:
			return state;
	}
}

export default combineReducers({
	ssoUrl,
	vergeCourses,
	requests: combineReducers({
		ssoUrl: createFetchReducer(GET_LEARNING_PORTAL_SSO_LINK),
		vergeCourses: createFetchReducer(GET_LEARNING_PORTAL_COURSES_FOR_VERGE),
	}),
});
