import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './styles';

class Loading extends Component {
	render() {
		const { classes } = this.props;
		return (
			<div className={`${classes.container} ${this.props.fullSize ? classes.fullSize : ''}`}>
				<div className={classes.loading}>
					<CircularProgress />
				</div>
			</div>
		);
	}
}

Loading.propTypes = {
	fullSize: PropTypes.bool,
};

export default withStyles(styles)(Loading);
