import { combineReducers } from 'redux';

import auth from './auth';
import guardianData from './guardianData';
import learningPortal from './learningPortal';
import news from './news';
import custodianData from './personUnderGuardianshipData';
import todos from './todos';

export default combineReducers({
	auth,
	custodianData,
	guardianData,
	todos,
	news,
	learningPortal,
});
