import '@babel/polyfill';
import 'moment/locale/nb';
import 'moment/locale/en-gb';
import './index.css';

import { applyMiddleware, createStore } from 'redux';
import { setDefaultLanguage, setTranslations } from './utils/translate';

import AppRouter from './AppRouter';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { createLogger } from 'redux-logger';
import en from '../translations/en.json';
import fetchMiddleware from './utils/fetchMidlleware';
import moment from 'moment';
import no from '../translations/no.json';
import rootReducer from './reducers';
import theme from './style';
import thunk from 'redux-thunk';

let reduxMiddlewares = [thunk, fetchMiddleware];
if (process.env.NODE_ENV !== 'production') {
	const loggerMiddleware = createLogger({
		collapsed: true,
		duration: true,
	});

	reduxMiddlewares = [...reduxMiddlewares, loggerMiddleware];
}

const store = createStore(rootReducer, applyMiddleware(...reduxMiddlewares));

let languages = [{
	id: 'en',
	language: 'English',
	translate: { en },
	locale: 'en-gb'
}, {
	id: 'no',
	language: 'Norsk',
	translate: { no },
	locale: 'nb'
}];

let userLang = navigator.language || navigator.userLanguage;
let langCode = userLang?.split('-')[0] ?? 'no';

let currLanguage = languages.find(item => item.id === langCode);
if (!currLanguage) {
	currLanguage = languages.find(item => item.id === 'no');
}

setTranslations(currLanguage.translate);
setDefaultLanguage(currLanguage.id);
moment.locale(currLanguage.locale);

ReactDOM.render(
	<Provider store={store}>
		<MuiThemeProvider theme={theme}>
			<AppRouter />
		</MuiThemeProvider>
	</Provider>,
	document.getElementById('app')
);
