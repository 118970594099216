import _get from 'lodash/get';
import config from '../../config';

const translations = {};
let defaultLanguage;

const defaultValue = config.env !== 'production' ? null : 'DOES NOT EXIST';

export function setTranslations(trans) {
	Object.assign(translations, trans);
}

export function setDefaultLanguage(lang) {
	defaultLanguage = lang;
}

export default function translate(key, useDefault = true) {
	return _get(translations, `${defaultLanguage}.${key}`, useDefault ? defaultValue : null);
}
