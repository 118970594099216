import { combineReducers } from 'redux';

import { clear, createFetchReducer, success } from '../utils/action-helpers';
import { CHECK_ROLE } from './auth';

const GET_NEWS = 'GET_NEWS';
const CREATE_NEWS = 'CREATE_NEWS';
const DELETE_NEWS = 'DELETE_NEWS';

const initialState = {
	news: [],
};

export function getNews() {
	return {
		type: GET_NEWS,
		url: '/api/news',
		fetchOptions: {},
		json: true,
	};
}

export function createNews(newsData) {
	return {
		type: CREATE_NEWS,
		url: '/api/news',
		fetchOptions: {
			method: 'POST',
			body: newsData,
		},
		json: true,
	};
}

export function deleteNews(id) {
	return {
		type: DELETE_NEWS,
		url: `/api/news/${id}`,
		fetchOptions: { method: 'DELETE' },
		additionalActionData: { id },
	};
}

export function clearCreateRequest() {
	return { type: clear(CREATE_NEWS) };
}

function news(state = initialState.news, action) {
	switch (action.type) {
		case success(GET_NEWS):
			return action.response;
		case success(CHECK_ROLE):
			return action.response.viewedByUserNews;
		case success(CREATE_NEWS):
			const sliceIndex = action.requestBody.id
				? state.findIndex((newsObject) => newsObject.id === action.requestBody.id)
				: state.length;
			return [
				...state.slice(0, sliceIndex),
				{
					...action.requestBody,
					id: action.response,
				},
				...state.slice(sliceIndex + 1),
			];
		case success(DELETE_NEWS):
			const deleteIndex = state.findIndex((newsObject) => newsObject.id === action.additionalActionData.id);
			return [...state.slice(0, deleteIndex), ...state.slice(deleteIndex + 1)];
		default:
			return state;
	}
}

export default combineReducers({
	news,
	requests: combineReducers({
		news: createFetchReducer(GET_NEWS),
		create: createFetchReducer(CREATE_NEWS),
	}),
});
