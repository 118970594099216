export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const CLEAR = 'CLEAR';

export function request(type) {
	return `${type}_${REQUEST}`;
}

export function success(type) {
	return `${type}_${SUCCESS}`;
}

export function failed(type) {
	return `${type}_${FAILED}`;
}

export function clear(type) {
	return `${type}_${CLEAR}`;
}

export function createFetchReducer(actionType, defaultState = {}) {
	return function fetchReducer(state = defaultState, action) {
		switch (action.type) {
			case request(actionType):
				return {
					...state,
					status: REQUEST,
				};
			case success(actionType):
				return {
					...state,
					status: SUCCESS,
					response: action.response,
				};
			case failed(actionType):
				return {
					...state,
					status: FAILED,
					error: action.error,
				};
			case clear(actionType):
				return defaultState;
			default:
				return state;
		}
	};
}
