import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import history from './history';
import loadable from '@loadable/component';

const App = loadable(() => import('./containers/App'));

const LoginSuccess = loadable(() => import('./containers/LoginSuccess'));

export default class AppRouter extends Component {
	goToDIFI() {
		const redirectUrl = encodeURI(`${window.location.protocol}//${window.location.host}/login-success`);
		fetch('/auth/loginUrl?' + new URLSearchParams({
			redirectUrl: redirectUrl
		}))
			.then((res) => res.json())
			.then((res) => {
				localStorage.setItem('codeChallenge', res.codeChallenge);
				window.location.href = res.url;
			});
	}

	endDIFISession() {
		fetch('/auth/endSessionUrl')
			.then((res) => res.json())
			.then((res) => {
				const redirectUrl = encodeURI(`${window.location.protocol}//${window.location.host}`);
				window.location.href = res.url.replace('{redirectUrl}', redirectUrl);
			});
	}

	render() {
		return (
			<Router history={history}>
				<Switch>
					<Route
						path={`/login`}
						component={() => {
							this.goToDIFI();
							return null;
						}}
					/>
					<Route path={`/login-success`} component={LoginSuccess}/>
					<Route
						path={`/`}
						render={(props) => (
							<App {...props} goToDIFI={() => this.goToDIFI()}
								 endDIFISession={() => this.endDIFISession()}/>
						)}
					/>
				</Switch>
			</Router>
		);
	}
}
