import cookie from 'cookie';
import { combineReducers } from 'redux';

import { createFetchReducer, success } from '../utils/action-helpers';

const LOGIN = 'LOGIN';
export const CHECK_ROLE = 'CHECK_ROLE';
const LOGOUT = 'LOGOUT';

const initialState = {
	user: {
		isLoggedIn: cookie.parse(document.cookie).isLoggedIn,
		isAdmin: false,
	},
};

export function login(code, codeChallenge, redirectUrl) {
	return {
		type: LOGIN,
		url: '/auth/login',
		fetchOptions: {
			method: 'POST',
			body: { code, codeChallenge, redirectUrl },
		},
		json: true,
	};
}

export function checkRole() {
	return {
		type: CHECK_ROLE,
		url: '/api',
		fetchOptions: {},
		json: true,
		skipRedirect: true,
	};
}

export function logout() {
	return {
		type: LOGOUT,
		url: '/auth/logout',
		fetchOptions: {},
		json: true,
	};
}

function user(state = initialState.user, action) {
	switch (action.type) {
		case success(LOGIN):
			return {
				...state,
				isLoggedIn: true,
			};
		case success(CHECK_ROLE):
			return {
				...state,
				isAdmin: action.response.role === 'ADMIN',
			};
		case success(LOGOUT):
			return initialState.user;
		default:
			return state;
	}
}

export default combineReducers({
	user,
	requests: combineReducers({
		login: createFetchReducer(LOGIN),
		logout: createFetchReducer(LOGOUT),
		role: createFetchReducer(CHECK_ROLE),
	}),
});
