import { combineReducers } from 'redux';

import { clear, createFetchReducer, success } from '../utils/action-helpers';

const GET_PERSONAL_INFO = 'GET_GUARDIAN_PERSONAL_INFO';
const GET_PROFESSIONAL_INFO = 'GET_GUARDIAN_PROFESSIONAL_INFO';

const initialState = {
	personalInfo: {
		person: {},
		address: {},
	},
	professionalInfo: {},
};

export function getPersonalInfo() {
	return {
		type: GET_PERSONAL_INFO,
		url: `/api/verge/person`,
		fetchOptions: {},
		json: true,
	};
}

export function clearPersonInfo() {
	return {
		type: clear(GET_PERSONAL_INFO),
	};
}

export function getProfessionalInfo() {
	return {
		type: GET_PROFESSIONAL_INFO,
		url: `/api/verge/professional`,
		fetchOptions: {},
		json: true,
	};
}

export function clearProfessionalInfo() {
	return {
		type: clear(GET_PROFESSIONAL_INFO),
	};
}

function personalInfo(state = initialState.personalInfo, action) {
	switch (action.type) {
		case success(GET_PERSONAL_INFO):
			return action.response;
		case clear(GET_PERSONAL_INFO):
			return initialState.personalInfo;
		default:
			return state;
	}
}

function professionalInfo(state = initialState.professionalInfo, action) {
	switch (action.type) {
		case success(GET_PROFESSIONAL_INFO):
			return action.response;
		case clear(GET_PROFESSIONAL_INFO):
			return initialState.professionalInfo;
		default:
			return state;
	}
}

export default combineReducers({
	personalInfo,
	professionalInfo,
	requests: combineReducers({
		personalInfo: createFetchReducer(GET_PERSONAL_INFO),
		professionalInfo: createFetchReducer(GET_PROFESSIONAL_INFO),
	}),
});
