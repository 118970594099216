import _findIndex from 'lodash/findIndex';
import moment from 'moment';
import { combineReducers } from 'redux';

import { createFetchReducer, success } from '../utils/action-helpers';

const GET_TODOS = 'GET_TODOS';
const ADD_TODO = 'ADD_TODO';
const UPDATE_TODO = 'UPDATE_TODO';
const DELETE_TODO = 'DELETE_TODO';

const SET_TODO_TO_EDIT = 'SET_TODO_TO_EDIT';
const UNSET_TODO_TO_EDIT = 'UNSET_TODO_TO_EDIT';
const CHANGE_TODO_TO_EDIT = 'CHANGE_TODO_TO_EDIT';

const initialState = {
	todos: [],
	todoToEdit: {
		todo: '',
		reminderDate: moment(),
		reminderTime: moment(),
	},
};

function formatReminderDate(todo) {
	//combined field from server
	if (todo.reminderDate && !todo.reminderTime) {
		return moment.utc(todo.reminderDate);
	}

	return moment(`${todo.reminderDate.format('YYYY-MM-DD')} ${todo.reminderTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
}

export function getTodos(sakId) {
	return {
		type: GET_TODOS,
		url: `/api/todo${sakId ? `/${sakId}` : ''}`,
		fetchOptions: {},
		json: true,
	};
}

export function addTodo(todo, sakId) {
	const todoToSend = {
		todo: todo.todo,
	};

	if (sakId) {
		todoToSend.sakId = sakId;
	}

	todoToSend.reminderDate = formatReminderDate(todo);

	return {
		type: ADD_TODO,
		url: '/api/todo',
		fetchOptions: {
			method: 'POST',
			body: todoToSend,
		},
		json: true,
	};
}

export function updateTodo(todo) {
	const todoToSend = {
		...todo,
	};

	todoToSend.reminderDate = formatReminderDate(todo);

	return {
		type: UPDATE_TODO,
		url: '/api/todo',
		fetchOptions: {
			method: 'POST',
			body: todoToSend,
		},
		json: true,
	};
}

export function deleteTodo(id) {
	return {
		type: DELETE_TODO,
		url: `/api/todo/${id}`,
		fetchOptions: { method: 'DELETE' },
		additionalActionData: { id },
	};
}

export function setTodoToEdit(todo) {
	return { type: SET_TODO_TO_EDIT, todo };
}

export function unsetTodoToEdit() {
	return { type: UNSET_TODO_TO_EDIT };
}

export function changeTodoToEdit(update) {
	return { type: CHANGE_TODO_TO_EDIT, update };
}

function todos(state = initialState.todos, action) {
	switch (action.type) {
		case success(GET_TODOS):
			return action.response;
		case success(ADD_TODO): {
			return [
				...state,
				{
					...action.requestBody,
					completed: false,
					id: action.response,
				},
			];
		}
		case success(UPDATE_TODO): {
			const isDateValid = action.requestBody.reminderDate && action.requestBody.reminderDate.isValid();
			const updateTodoIndex = _findIndex(state, {
				id: action.requestBody.id,
			});
			return [
				...state.slice(0, updateTodoIndex),
				{
					...action.requestBody,
					reminderDate: isDateValid ? action.requestBody.reminderDate : null,
				},
				...state.slice(updateTodoIndex + 1),
			];
		}
		case success(DELETE_TODO):
			const activeTodoIndex = _findIndex(state, {
				id: action.additionalActionData.id,
			});
			return [...state.slice(0, activeTodoIndex), ...state.slice(activeTodoIndex + 1)];
		default:
			return state;
	}
}

function todoToEdit(state = initialState.todoToEdit, action) {
	switch (action.type) {
		case SET_TODO_TO_EDIT: {
			const momentDate = moment.utc(action.todo.reminderDate).local();
			return {
				...action.todo,
				reminderDate: momentDate,
				reminderTime: momentDate,
			};
		}
		case UNSET_TODO_TO_EDIT: {
			return initialState.todoToEdit;
		}
		case CHANGE_TODO_TO_EDIT: {
			return {
				...state,
				...action.update,
			};
		}
		default:
			return state;
	}
}

const getRequest = createFetchReducer(GET_TODOS);
const addRequest = createFetchReducer(ADD_TODO);
const updateRequest = createFetchReducer(UPDATE_TODO);
const deleteRequest = createFetchReducer(DELETE_TODO);

export default combineReducers({
	todos,
	todoToEdit,
	requests: combineReducers({
		getRequest,
		addRequest,
		updateRequest,
		deleteRequest,
	}),
});
