import { createTheme } from '@material-ui/core';

const defaultFontsOpts = {
	fontStyle: 'normal',
	letterSpacing: 'normal',
	color: '#000',
	mixBlendMode: 'normal',
	lineHeight: 1.2,
};

export default createTheme({
	palette: {
		primary: {
			main: '#0077D9',
			light: '#84DAE8',
			dark: '#00548A',
		},
		secondary: {
			main: '#F23225',
		},
	},
	typography: {
		h1: {
			...defaultFontsOpts,
			fontWeight: 500,
			fontSize: 32,
		},
		h2: {
			...defaultFontsOpts,
			fontWeight: 500,
			fontSize: 27,
		},
		h3: {
			...defaultFontsOpts,
			fontWeight: 500,
			fontSize: 24,
			opacity: 0.87,
		},
		h4: {
			...defaultFontsOpts,
			fontWeight: 500,
			fontSize: 16,
			lineHeight: 1.5,
		},
		body2: {
			...defaultFontsOpts,
			fontWeight: 300,
			fontSize: 18,
			lineHeight: '32px',
		},
		caption: {
			...defaultFontsOpts,
			fontWeight: 500,
			fontSize: 14,
			lineHeight: 1,
			color: 'rgba(0, 0, 0, 0.3)',
		},
		overline: {
			...defaultFontsOpts,
			fontWeight: 500,
			fontSize: 12,
			lineHeight: 1.5,
			letterSpacing: 2,
			textTransform: 'uppercase',
		},
		button: {
			textTransform: 'none',
			fontSize: 16,
		},
	},
});
