import 'whatwg-fetch';

import { failed, request, success } from './action-helpers';

import history from '../history';

export default () => (dispatch) => async (action) => {
	let fetchOptions = action.fetchOptions;
	let additionalActionData = action.additionalActionData;
	const url = action.url;
	const isJSON = !!action.json;
	const isFormData = !!action.formData;

	if (!url) {
		return dispatch(action);
	}

	const requestBody = fetchOptions.body;

	if (requestBody) {
		if (isJSON) {
			fetchOptions = {
				...fetchOptions,
				body: JSON.stringify(requestBody),
			};
		} else {
			const searchParams = Object.keys(requestBody)
				.map((key) => {
					return encodeURIComponent(key) + '=' + encodeURIComponent(requestBody[key]);
				})
				.join('&');
			fetchOptions = {
				...fetchOptions,
				body: searchParams,
			};
		}
	}

	dispatch({ ...action, type: request(action.type) });

	if (isJSON) {
		fetchOptions = {
			...fetchOptions,
			headers: {
				...fetchOptions.headers,
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		};
	}

	if (isFormData) {
		fetchOptions = {
			...fetchOptions,
			headers: {
				...fetchOptions.headers,
				Accept: 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
		};
	}

	let response = await fetch(`${url}`, fetchOptions);
	if (!response.ok) {
		if (response.status === 404 && !action.skipRedirect) {
			return history.replace(`/`);
		}

		return dispatch({
			...action,
			requestBody,
			additionalActionData,
			error: response,
			type: failed(action.type),
		});
	}

	if (isJSON || isFormData) {
		response = await response.json();
	}
	dispatch({
		...action,
		requestBody,
		additionalActionData,
		response,
		type: success(action.type),
	});
	return response;
};
