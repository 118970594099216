export default {
	container: {
		display: 'flex',
		flexGrow: 1,
	},
	fullSize: {
		height: '100vh',
	},
	loading: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
};
