import _copyDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { combineReducers } from 'redux';

import { clear, createFetchReducer, success } from '../utils/action-helpers';

const GET_CASES = 'GET_CASES';
const GET_PERSONAL_INFO = 'GET_PERSONAL_INFO';
const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO';
const GET_CASE_INFO = 'GET_CASE_INFO';
const GET_RELATIVE_INFO = 'GET_RELATIVE_INFO';
const GET_ACTIVITY_INFO = 'GET_ACTIVITY_INFO';
const GET_ACCOUNTS_INFO = 'GET_ACCOUNTS_INFO';
const GET_TRANSACTIONS = 'GET_TRANSACTIONS';

const initialState = {
	cases: [],
	personalInfo: {
		personInfo: {},
		officialAddress: {},
		secondaryAddress: {},
	},
	caseInfo: [],
	relativesInfo: [],
	activityInfo: [],
	accountsInfo: [],
	transactions: [],
};

export function getCases() {
	return {
		type: GET_CASES,
		url: '/api/verge/saker',
		fetchOptions: {},
		json: true,
	};
}

export function getPersonalInfo(sakId, pmwId) {
	return {
		type: GET_PERSONAL_INFO,
		url: `/api/sak/${sakId}/person/${pmwId}`,
		fetchOptions: {},
		json: true,
	};
}

export function updatePersonalInfo(updates) {
	updates = Array.isArray(updates) ? updates : [updates];
	return {
		type: UPDATE_PERSONAL_INFO,
		updates,
	};
}

export function clearPersonInfo() {
	return {
		type: clear(GET_PERSONAL_INFO),
	};
}

export function getCaseInfo(id) {
	return {
		type: GET_CASE_INFO,
		url: `/api/sak/${id}`,
		fetchOptions: {},
		json: true,
	};
}

export function clearCaseInfo() {
	return {
		type: clear(GET_CASE_INFO),
	};
}

export function getRelativesInfo(sakId, pmwId) {
	return {
		type: GET_RELATIVE_INFO,
		url: `/api/sak/${sakId}/person-relatives/${pmwId}`,
		fetchOptions: {},
		json: true,
	};
}

export function clearRelativesInfo() {
	return {
		type: clear(GET_RELATIVE_INFO),
	};
}

export function getActivityInfo(id) {
	return {
		type: GET_ACTIVITY_INFO,
		url: `/api/sak/${id}/activities`,
		fetchOptions: {},
		json: true,
	};
}

export function clearActivityInfo() {
	return {
		type: clear(GET_ACTIVITY_INFO),
	};
}

export function getAccountsInfo(sakId) {
	return {
		type: GET_ACCOUNTS_INFO,
		url: `/api/sak/${sakId}/bank-accounts`,
		fetchOptions: {},
		json: true,
	};
}

export function clearAccountsInfo() {
	return {
		type: clear(GET_ACCOUNTS_INFO),
	};
}

export function getTransactions(sakId, accountNumber, year) {
	return {
		type: GET_TRANSACTIONS,
		url: `/api/sak/${sakId}/bank-transactions`,
		fetchOptions: {
			method: 'POST',
			body: {
				accountNumber,
				year,
			},
		},
		formData: true,
	};
}

export function clearTransactions() {
	return {
		type: clear(GET_TRANSACTIONS),
	};
}

function cases(state = initialState.cases, action) {
	switch (action.type) {
		case success(GET_CASES):
			return action.response;
		default:
			return state;
	}
}

function personalInfo(state = initialState.personalInfo, action) {
	switch (action.type) {
		case success(GET_PERSONAL_INFO):
			return action.response;
		case UPDATE_PERSONAL_INFO:
			let stateCopy = _copyDeep(state);
			action.updates.forEach((update) => {
				_set(stateCopy, update.key, update.value);
			});
			return stateCopy;
		case clear(GET_PERSONAL_INFO):
			return initialState.personalInfo;
		default:
			return state;
	}
}

function caseInfo(state = initialState.caseInfo, action) {
	switch (action.type) {
		case success(GET_CASE_INFO):
			return action.response;
		case clear(GET_CASE_INFO):
			return initialState.caseInfo;
		default:
			return state;
	}
}

function relativesInfo(state = initialState.relativesInfo, action) {
	switch (action.type) {
		case success(GET_RELATIVE_INFO):
			return action.response;
		case clear(GET_RELATIVE_INFO):
			return initialState.relativesInfo;
		default:
			return state;
	}
}

function activityInfo(state = initialState.activityInfo, action) {
	switch (action.type) {
		case success(GET_ACTIVITY_INFO):
			return action.response;
		case clear(GET_ACTIVITY_INFO):
			return initialState.activityInfo;
		default:
			return state;
	}
}

function accountsInfo(state = initialState.accountsInfo, action) {
	switch (action.type) {
		case success(GET_ACCOUNTS_INFO):
			return action.response;
		case clear(GET_ACCOUNTS_INFO):
			return initialState.accountsInfo;
		default:
			return state;
	}
}

function transactions(state = initialState.transactions, action) {
	switch (action.type) {
		case success(GET_TRANSACTIONS):
			return action.response;
		case clear(GET_TRANSACTIONS):
			return initialState.transactions;
		default:
			return state;
	}
}

export default combineReducers({
	cases,
	personalInfo,
	caseInfo,
	relativesInfo,
	activityInfo,
	accountsInfo,
	transactions,
	requests: combineReducers({
		cases: createFetchReducer(GET_CASES),
		personalInfo: createFetchReducer(GET_PERSONAL_INFO),
		caseInfo: createFetchReducer(GET_CASE_INFO),
		relativesInfo: createFetchReducer(GET_RELATIVE_INFO),
		activityInfo: createFetchReducer(GET_ACTIVITY_INFO),
		accountsInfo: createFetchReducer(GET_ACCOUNTS_INFO),
		transactions: createFetchReducer(GET_TRANSACTIONS),
	}),
});
